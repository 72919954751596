<template>
  <div>
    <px-capa :banner-capa="headerMaterial">
      <template v-slot:title>
        Materiais de escritório para sua empresa
      </template>
      <template v-slot:text>
        Cadastre-se e conheça uma nova forma de comprar para sua empresa
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/d/24"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-home-page-block
        show-link
        link="/q/g/84"
        class="mt-12"
        :query="queryPapel"
        title="Papéis e post-it"
        :qtd-ofertas="20"/>
      <v-row dense no-gutters class="mt-5">
        <v-col cols="6" class="d-flex align-center px-2"
               v-for="(banner, index) in bannersDuplos" :key="index">
          <px-home-banner-card :title="banner.titulo"
                               :link="banner.link"
                               :banner-image="banner.foto"
                               gradient="none"
                               :max-height="height"
                               :min-height="height"/>
        </v-col>
      </v-row>
      <px-home-page-block
        show-link
        link="/q/g/202"
        class="mt-10"
        :query="queryOrganizadores"
        title="Organizadores"
        :qtd-ofertas="20"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import HomeBannerCard from '@/modules/home-page/HomeBannerCard.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import RdDuvidaFormSubscription from '@/modules/integration/rd/RdDuvidaFormSubscription.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import headerMaterial from '../../assets/landing-page/material-escritorio/header-escritorio.png';
import bannerDuploPapel from '../../assets/landing-page/material-escritorio/papel-post-it.png';
import bannerDuploPapelMobile from '../../assets/landing-page/material-escritorio/papies-mobile.png';
import bannerDuploClips from '../../assets/landing-page/material-escritorio/clips-papel.png';
import bannerDuploClipsMobile from '../../assets/landing-page/material-escritorio/clipe-mobile.png';
import promoPapalA4 from '../../assets/landing-page/material-escritorio/promos/papel-a4.png';
import promoPapalA4Mobile from '../../assets/landing-page/material-escritorio/promos/papale-mobile.png';
import promoEstilete from '../../assets/landing-page/material-escritorio/promos/estilete.png';
import promoEstileteMonile from '../../assets/landing-page/material-escritorio/promos/estilete-mobile.png';
import promoCaneta from '../../assets/landing-page/material-escritorio/promos/caneta-bic.png';
import promoCanetaMobile from '../../assets/landing-page/material-escritorio/promos/caneta-mobile.png';

const DEPARTAMENTO_ID = 24;

export default {
  mixins: [gridMixin],
  data() {
    return {
      headerMaterial,
      query: `disponivel is true and departamento.id = ${DEPARTAMENTO_ID} order by random`,
      queryPapel: 'grupo.id = 84 and disponivel is true order by relevancia',
      queryOrganizadores: 'grupo.id = 202 and disponivel is true order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os materiais de escritório para sua empresa',
      banners: [],
      bannersDuplos: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '300px';
      }
      return '360px';
    },
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(promoPapalA4, promoPapalA4Mobile),
        link: '/oferta/18448',
      },
      {
        foto: this.getGridImg(promoEstilete, promoEstileteMonile),
        link: '/oferta/2977',
      },
      {
        foto: this.getGridImg(promoCaneta, promoCanetaMobile),
        link: '/oferta/2973',
      },
    ];
    this.bannersDuplos = [
      {
        foto: this.getGridImg(bannerDuploPapel, bannerDuploPapelMobile),
        link: '/q/g/84',
      },
      {
        foto: this.getGridImg(bannerDuploClips, bannerDuploClipsMobile),
        link: '/q/c/244',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxHomeBannerCard: HomeBannerCard,
    pxLandingPageBanners: LandingPageBanners,
    pxCapa: Capa,
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
    pxFaqLandingPage: FaqLandingPage,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
